import type { CreateSession } from '~/server/trpc/routers/customerModule/accessCode/schema'

function useCustomerModuleAccessCodeSession() {
  const accessCode = useCookie<string | undefined>('hos.access_code', {
    default: () => undefined,
  })
  const accessCodeSession = useCookie<string | undefined>('hos.access_code_session', {
    default: () => undefined,
  })
  const { $trpc } = useNuxtApp()

  async function createSession(data: CreateSession) {
    const result = await $trpc.customerModule.accessCode.createSession.mutate(data)
    accessCodeSession.value = result.id
    accessCode.value = result.codeValue
    return {
      session: result.id,
      code: result.codeValue,
    }
  }

  function terminateSession() {
    accessCode.value = undefined
    accessCodeSession.value = undefined
  }

  return {
    accessCode,
    accessCodeSession,
    createSession,
    terminateSession,
  }
}

// `createSharedComposable` leads to state being shared on server - only use on client
export default import.meta.server
  ? useCustomerModuleAccessCodeSession
  : createSharedComposable(useCustomerModuleAccessCodeSession)
